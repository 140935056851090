import React from "react"
import './App.css';
import Pagaes from "./components/pages/Pages";

function App() {
  return (
    <>
      <Pagaes />
    </>
  );
}

export default App;
